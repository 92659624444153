<template>
  <div class="o-row o-row--lg">
    <div class="o-container">
      <div class="c-terms">
        <h2>Algemene voorwaarden</h2>

        <div class="c-terms__text">
          <h3>Algemeen</h3>
          <p>
            De gebruiksvoorwaarden op deze pagina zijn van toepassing op de websites
            www.vendingmapp.com en alle bijhorende kanalen zoals onder meer nieuwsbrieven, blogs,
            social media, ...
          </p>
          <p>
            Door gebruik te maken van onze website(s) verbind je je ertoe deze gebruiksvoorwaarden
            na te leven en te aanvaarden.
          </p>
          <p>
            De websites zijn gecre&euml;erd en worden beheerd door
            <strong>De Maerteleire Invest</strong>, Kwaadstraat 19, B-9810 Nazareth, BTW nummer BE
            0730 646 362
          </p>
          <h3>Niet-toegestaan gebruik</h3>
          <p>
            Bij het gebruik van de websites verbind je je er o.m. toe om je te gedragen als een
            verantwoordelijke en zorgvuldige internetgebruiker wat betekent:
          </p>
          <ul>
            <li>
              De informatie op deze website niet op een onrechtmatige, onwettelijke manier te
              gebruiken, noch op een wijze die direct of indirect schade kan toebrengen aan De
              Maerteleire Invest, aan de met De Maerteleire Invest verbonden ondernemingen of aan
              service providers, distributeurs, klanten van De Maerteleire Invest of enige andere
              derde.
            </li>
            <li>
              De website niet te gebruiken op een dergelijke wijze dat er een inbreuk gepleegd wordt
              op de rechten van een natuurlijke persoon, rechtspersoon of vereniging, zoals onder
              meer, doch niet uitsluitend, de rechten van privacy en intellectuele eigendom
            </li>
            <li>
              De website niet te gebruiken op een dergelijke wijze dat er een inbreuk gepleegd wordt
              op de rechten van een natuurlijke persoon, rechtspersoon of vereniging, zoals onder
              meer, doch niet uitsluitend, de rechten van privacy en intellectuele eigendom.
            </li>
            <li>
              De website te gebruiken in overeenstemming met de toepasselijke specifieke
              gedragscode(s) en kennisgevingen die op de sites waartoe verwezen wordt, gepubliceerd
              zijn.
            </li>
            <li>
              Geen zodanig gebruik te maken van de website waardoor de website wordt beschadigd,
              vervormd, onderbroken, stopgezet of anderszins minder effici&euml;nt wordt gemaakt.
            </li>
            <li>
              Om op geen enkele wijze misbruik te maken van de website en de platformen aangeboden
              door De Maerteleire Invest.
            </li>
            <li>
              De website niet te gebruiken voor transmissie of het posten van computervirussen, of
              voor transmissie of het posten van illegaal of onrechtmatig materiaal, of van
              materiaal dat op enige wijze ongepast is (met inbegrip van, maar niet beperkt tot
              materiaal met een lasterlijk, obsceen of bedreigend karakter).
            </li>
            <li>
              De eer en goede naam van De Maerteleire Invest en haar merken te allen tijde te
              vrijwaren.
            </li>
            <li>
              De website niet te gebruiken voor het plaatsen of verzenden van materiaal voor
              promotie- of reclamedoeleinden zonder voorafgaande toestemming van De Maerteleire
              Invest, tenzij dergelijke inhoud is aangevraagd door de ontvanger.
            </li>
            <li>
              De Maerteleire Invest onmiddellijk te verwittigen van elke aanval, zelfs potentieel op
              de veiligheid van de website of de rechten van derden.
            </li>
          </ul>
          <p>
            Wanneer je de intellectuele rechten, andere rechten van De Maerteleire Invest of derden
            miskent, verbind je je ertoe De Maerteleire Invest of derden te vergoeden en te
            vrijwaren tegen enige aanspraken of vorderingen van De Maerteleire Invest of derden als
            gevolg van een inbreuk hierop.
          </p>
          <p>
            De Maerteleire Invest werkt samen met externe partners om bepaalde informatie en
            diensten via deze site aan te bieden. De Maerteleire Invest kan in geen geval
            aansprakelijk worden gesteld voor schendingen van de wet, intellectuele eigendomsrechten
            of andere rechten van derden door dergelijke partners en wijst elke aansprakelijkheid
            hieromtrent uitdrukkelijk af. De Maerteleire Invest kan eveneens niet aansprakelijk
            gesteld worden voor onjuistheden in de informatie van derden.
          </p>
          <h3>Merken, handelsnaam en intellectuele eigendom</h3>
          <p>
            De teksten, tekeningen, foto's, films, beelden, data, databanken, software, benamingen,
            handels- en domeinnamen, merken, logo's en andere bestanddelen van deze site zijn
            beschermd door intellectuele rechten en behoren De Maerteleire Invest of derden toe. De
            site is tevens een databank beschermd door het auteursrecht en de wetgeving inzake de
            bescherming van databanken. Het is verboden zonder voorafgaand schriftelijk akkoord van
            De Maerteleire Invest &nbsp;de op en via deze website aangeboden informatie geheel of
            gedeeltelijk op te slaan (anders dan noodzakelijk om de website te kunnen bekijken), te
            reproduceren, te wijzigen, openbaar te maken, te distribueren of te verzenden, te
            verkopen of anderszins over te dragen of enige rechten hierop te verlenen aan derden.
            Korte aanhalingen uit artikels zijn toegestaan, tenzij voor commerci&euml;le doeleinden,
            mits duidelijke vermelding van de bron (m&eacute;t hyperlink), &eacute;n zonder dat dit
            systematisch mag gebeuren.
          </p>
          <p>
            Het logo en de benaming van De Maerteleire Invest &nbsp;haar merken en/of handelsnamen
            zijn wettelijk beschermd. Elk gebruik ervan of van gelijkaardige tekens is volstrekt
            verboden zonder het voorafgaandelijk en schriftelijk akkoord van De Maerteleire Invest .
          </p>
          <p>
            Wij zijn de exclusieve rechthebbende of rechtverkrijgende van het geheel van de
            intellectuele eigendomsrechten verbonden aan onze website en de aangeboden dienst.
          </p>
          <p>
            Tenzij schriftelijk en uitdrukkelijk anders werd overeengekomen, dragen gebruikers alle
            intellectuele eigendomsrechten die zij kunnen laten gelden op o.a. teksten,
            foto&rsquo;s, video's, tekeningen, grafieken, beelden, geluid en data die door de
            gebruikers worden geplaatst op onze website of die door gebruikers aan ons worden
            verzonden om ze te plaatsen om op onze website, onherroepelijk aan ons over.
          </p>
          <p>
            Je verbindt je ertoe op geen enkele wijze en onder geen enkele vorm dan ook het geheel
            van elementen waaruit onze website bestaat, of een deel ervan, te kopi&euml;ren, te
            bewerken, uit te baten of door te verkopen of op enige andere wijze te gebruiken. Deze
            elementen zijn onder andere het merk, de teksten, de software, de databases, de
            formulieren, de handelsnamen, de productnamen, de logo&rsquo;s, de grafische elementen
            en illustraties, de grafieken, de muziek, de kleurcombinaties, de slogans, de lay outs
            en bladschikkingen en elk ander voor bescherming in aanmerking komend element van de
            website en van onze dienst.
          </p>
          <h3>Persoonsgegevens, privacy &amp; GDPR</h3>
          <p>
            De Maerteleire Invest respecteert jouw privacy en stelt alles in het werk deze te
            beschermen. We zijn compliant met de GDRP/AVG wetgeving (Europese Verordening 2016/679
            van 27 april 2016 betreffende de bescherming van persoonsgegevens). Met de publicatie
            van deze tekst informeren we je over ons privacybeleid en het juridische kader ervan. De
            Maerteleire Invest, verzamelt geen persoonsgegevens tenzij deze op vrijwillige basis
            worden verstrekt.
          </p>
          <h3>
            De Maerteleire Invest zal jouw persoonsgegevens uitsluitend voor volgende doeleinden
            gebruiken:
          </h3>
          <p>
            Voor het aanmaken van een account, plaatsen van een order, invullen en versturen van een
            formulier, ... of het verzenden van een e-mail geef je als bezoeker De Maerteleire
            Invest toestemming om je persoonlijke persoonsgegevens (naam, voornaam, e-mail,
            telefoonnummer, geboortedatum, adres, IP, ...) op te slaan met het oog op een
            geautomatiseerde gebruikersprofilering, klantenbeheer, marktonderzoek en direct mail per
            post. De rechtsgrond hiervoor is de uitvoering van een overeenkomst die door jou
            gevraagd wordt en bij het geven van een op-in jouw expliciete voorafgaande toestemming
            voor het versturen van nieuwsbrieven en andere direct marketing.
          </p>
          <p>
            Jouw elektronische contactgegevens (e-mail, IP, social media, ...) kunnen, zoals
            hierboven aangegeven, door De Maerteleire Invest worden gebruikt voor direct e-mail
            marketing indien je hiermee ingestemd hebt. Evenwel is jouw voorafgaande instemming niet
            vereist wanneer De Maerteleire Invest elektronische contactgegevens die zij heeft
            verkregen bij de levering van bepaalde producten of diensten (inclusief gratis diensten
            zoals accounts, bedrijfsvermeldingen, ...), gebruikt voor direct e-mail marketing.
            Uitschrijven op de nieuwsbrieven van De Maerteleire Invest kan eenvoudig door op de
            'uitschrijven' link in de nieuwsbrieven zelf te klikken. Jouw e-mail wordt meteen van de
            verzendlijst geschrapt. Bij vragen of problemen kan je per mail terecht via
            info@vendingmapp.com, per post Kwaadstraat 19, B-9810 Nazareth'.
          </p>
          <p>
            Voor de optimale werking van sommige van onze diensten (zoals bedrijfsvermeldingen,
            gebruikersaccounts, ...) ontvang je e-mails die inherent deel uitmaken van de service,
            waardoor ze geen uitschrijflink bevatten (uitschrijven kan in dit geval door je&nbsp;<a
              href="https://www.eventplanner.be/help/54_hoe-verwijder-ik-mijn-bedrijf-van-eventplannerbe-nl.html"
            >persoonsgegevens te verwijderen</a>&nbsp;en zo ook de dienst stop te zetten). Met als rechtsgrond de uitvoering van een
            overeenkomst die door jou gevraagd wordt en de legitieme belangen van De Maerteleire
            Invest om haar website en dienstverlening voortdurend te verbeteren.
          </p>
          <p>
            Door het aanmaken van een bedrijfsaccount geef je ons uitdrukkelijk de toestemming,
            wanneer jouw e-mailadres ongeldig zou worden (bijvoorbeeld bij verandering bedrijfsnaam,
            het verlaten van het bedrijf, ...), om je e-mailadres te vervangen door het publiek
            beschikbare e-mailadres vermeld op je website zodat we de dienstverlening zonder
            onderbreking kunnen verder zetten.
          </p>
          <h3>Doorgifte aan derden</h3>
          <p>
            De verstrekte gegevens kunnen voor deze doeleinden worden doorgegeven aan de met De
            Maerteleire Invest contractueel verbonden ondernemingen of medewerkers in het kader van
            onze dienstverlening. We verkopen of verhuren jouw persoonsgegevens niet aan derden. Bij
            gehele of gedeeltelijk reorganisatie of overdracht van activiteiten van De Maerteleire
            Invest, waarbij zij haar bedrijfsactiviteiten reorganiseert, overdraagt, staakt of
            indien De Maerteleire Invest failliet gaat, kan dit betekenen dat jouw gegevens aan
            nieuwe entiteiten of derden, middels welke de bedrijfsactiviteiten van De Maerteleire
            Invest geheel of gedeeltelijk worden uitgevoerd, worden overgedragen. De Maerteleire
            Invest zal in redelijkheid pogen je van tevoren op de hoogte te stellen van het feit dat
            De Maerteleire Invest jouw gegevens aan genoemde derde bekend maakt, maar je erkent
            tevens dat dit niet onder alle omstandigheden technisch of commercieel haalbaar is.
          </p>
          <h3>Automatisch vergaarde niet-persoonlijke informatie</h3>
          <p>
            De Maerteleire Invest kan anonieme of geaggregeerde gegevens verzamelen van
            niet-persoonlijke aard, zoals browser type of IP-adres, het besturingsprogramma dat je
            gebruikt, de pagina&rsquo;s die je op onze website bezoekt, ... of de domeinnaam van de
            website langs waar je naar de website gekomen bent, of waarlangs je die verlaat. We
            gebruiken deze gegevens voor het verbeteren van onze website en nemen deze op in
            anonieme statistieken. Als rechtsgrond voor deze verwerking geldt de legitieme belangen
            van De Maerteleire Invest om haar website en dienstverlening voortdurend te verbeteren.
            Deze gegevens kunnen eveneens worden gebruikt om je aanbiedingen en promoties te tonen
            of betreffende producten en diensten die jou kunnen interesseren mee te delen met als
            rechtsgrond de legitieme belangen van De Maerteleire Invest om haar website en
            dienstverlening voortdurend te verbeteren.
          </p>
          <h3>Cookies</h3>
          <p>
            Tijdens een bezoek aan de site kunnen automatisch zogenaamde 'cookies' op de harde
            schijf van jouw computer geplaatst worden. Deze gegevens helpen ons de site beter af te
            stemmen op de wensen en voorkeuren van de bezoekers en kunnen eveneens worden gebruikt
            om u aanbiedingen en promoties te tonen of betreffende producten en diensten die jou
            kunnen interesseren mee te delen. Met de meeste internetbrowsers kun je cookies van je
            harde schijf verwijderen, cookies afwijzen of een waarschuwing ontvangen vooraleer een
            cookie ge&iuml;nstalleerd wordt. Raadpleeg de instructies of help-functie van jouw
            internetbrowser of&nbsp;<a
              href="http://www.youronlinechoices.com/be-nl/"
            >deze website</a>&nbsp;voor meer details. Indien je tijdens een bepaald bezoek aan de site instemt met
            het gebruik van cookies, kunnen deze cookies eventueel verder worden gebruikt bij
            volgende verbindingen met de site.
          </p>
          <p>
            Verder integreren we met 3de partijen die noodzakelijk zijn voor de werking en
            veiligheid van onze site. Deze partijen kunnen in dit kader data van gebruikers
            verzamelen. Zo gebruiken we onder meer Google's reCAPTCHA om onze website te beschermen
            (data collectie in overeenstemming met de Google Privacy Policy), Google Analytics voor
            anonieme bezoekersanalyses, Google DFP voor display advertising, Facebook pixel voor een
            betere cross-platform gebruikservaring en conversie tracking, de chat voor optimale
            support en ondersteuning. De cookievoorkeuren kunnen ingesteld worden op de website.
          </p>
          <h3>De website gebruikt volgende cookies:</h3>
          <p><strong>Level 0 - Functionele cookies</strong></p>
          <ul>
            <li>
              <strong>PHPSESSION en pmToken van Vendingmapp.com</strong><br>
              Met deze cookies zorgen we dat je veilig kunt inloggen op onze site en dat de
              basisfunctionaliteiten van onze website kunnen werken.<br>
              (bewaartijd: sessie / 10 jaar)
            </li>
            <li>
              <strong>pmML van Vendingmapp.com</strong><br>
              Met deze cookie verbeteren we de werking van onze website door analytics te verzamelen
              en onze content te personaliseren voor een nog betere gebruikservaring.<br>
              (bewaartijd: 10 jaar)
            </li>
            <li>
              <strong>eventToken van Vendingmapp.com</strong><br>
              Ook deze cookie helpt ons je veilig te doen inloggen en zorgt er tevens voor dat,
              indien je dat wenst, we je automatisch opnieuw kunnen inloggen als je opnieuw op onze
              website komt (vink 'onthoud mij' aan bij het inloggen).<br>
              (bewaartijd: 20 jaar)
            </li>
            <li>
              <strong>_stripe van Stripe</strong><br>
              Bestel je iets via onze website? Dan handelen we jouw betaling af via onze veilige
              beveiligingspartner Stripe.<br>
              (bewaartijd: 6 maanden)
            </li>
          </ul>
          <p><strong>Level 1 - Functionele &amp; analytics cookies</strong></p>
          <ul>
            <li>
              <strong>_ga, _gid, _gat, AMP_TOKEN, _gac, ... van Google Analytics</strong><br>
              Via Google analytics verzamelen we anonieme bezoekersstatistieken, waarmee we onze
              website voortdurend verbeteren.<br>
              (bewaartijd: _ga (2 jaar), _gid (24u), _gat (1min), AMP_TOKEN (30sec tot 1 jaar), _gac
              (90 dagen))
            </li>
          </ul>
          <p><strong>Level 2 - Social media en advertenties</strong></p>
          <ul>
            <li>
              <strong>_gads, DoubleClick, ... by Google DFP / Google Adsense / Google Adwords</strong><br>
              We gebruiken Google DFP voor het weergeven van banners op onze website. Google Adsense
              gebruiken we voor relevante tekstadvertenties. Banners, en andere advertenties, zorgen
              voor de broodnodige inkomsten waarmee wij de sterke content kunnen maken die we je
              gratis ter beschikking stellen.<br>
              (bewaartijd: 2 jaar)
            </li>
            <li>
              <strong>Facebook Audience pixel van Facebook</strong><br>
              Veel van onze gebruikers volgen onze media via Facebook. Voor een optimale
              gebruikservaring tussen de twee platformen plaatsen we ook een Facebook cookie.
              Daarmee kunnen we voorkomen dat je bepaalde boodschappen te zien krijgt wanneer je ze
              eerder al zag op onze website of net omgekeerd zodat we je relevante advertenties
              kunnen tonen. De cookie wordt ook gebruikt om conversies te meten van campagnes die we
              op Facebook draaien.<br>
              (bewaartijd: onbekend)
            </li>
            <li>
              <strong>LinkedIn Insights pixel van LinkedIn</strong><br>
              Veel van onze gebruikers volgen onze media via LinkedIn. Voor een optimale
              gebruikservaring tussen de twee platformen plaatsen we ook een LinkedIn cookie.
              Daarmee kunnen we voorkomen dat je bepaalde boodschappen te zien krijgt wanneer je ze
              eerder al zag op onze website of net omgekeerd zodat we je relevante advertenties
              kunnen tonen. De cookie wordt ook gebruikt om conversies te meten van campagnes die we
              op LinkedIn draaien.<br>
              (bewaartijd: 6 maand)
            </li>
          </ul>
          <h3>IP-adressen</h3>
          <p>
            Bij het posten van advertenties, automaten, reacties, reviews, ... wordt uw IP-adres
            opgeslagen. Het doel hiervan is de authenticiteit en de integriteit van de berichten te
            verhogen door te vermijden dat iemand berichten post in andermans naam. In geval van
            klachten over vermeend onwettelijk gedrag, kan dit IP-adres ook doorgegeven worden aan
            de bevoegde gerechtelijke instanties.
          </p>
          <h3>Duur van de verwerking</h3>
          <p>
            Jouw persoonsgegevens worden door ons bewaard en verwerkt voor een periode die
            noodzakelijk is in functie van de doeleinden van de verwerking en in functie van de
            contractuele relatie tussen jou en De Maerteleire Invest. Omdat je als gebruiker
            levenslange toegang hebt tot onze diensten, downloads, ...is er voor accounts geen
            vastgelegde vervaldatum van persoonsgegevens.
          </p>
          <h3>Jouw rechten</h3>
          <ul>
            <li>
              Recht van toegang en inzage:<br>
              Je hebt het recht om op elk moment gratis kennis te nemen van jouw persoonsgegevens,
              alsook van het gebruik dat wij van jouw persoonsgegevens maken.
            </li>
            <li>
              Recht van verbetering, verwijdering en beperking:<br>
              Je bent vrij om jouw persoonsgegevens al dan niet mee te delen aan De Maerteleire
              Invest. Daarnaast heb je steeds het recht om ons te verzoeken jouw persoonsgegevens te
              verbeteren, aan te vullen of te verwijderen. Je kunt dit ook eenvoudig zelf door in te
              loggen op onze website. Je erkent dat bij weigering van mededeling of verzoek tot
              verwijdering van persoonsgegevens, bepaalde diensten en producten niet leverbaar zijn.
              Je mag eveneens vragen om de verwerking van uw Persoonsgegevens te beperken.
            </li>
            <li>
              Recht van verzet:<br>
              Je beschikt eveneens over een recht van verzet tegen de verwerking van jouw
              persoonsgegevens om ernstige en legitieme redenen. Daarnaast heb je steeds het recht
              om je te verzetten tegen het gebruik van persoonsgegevens voor doeleinden van direct
              marketing; in dergelijk geval hoef je geen redenen op te geven.
            </li>
            <li>
              Recht van vrije gegevensoverdracht:<br>
              Je beschikt over het recht om jouw Persoonsgegevens die door ons verwerkt worden te
              verkrijgen en/of aan andere verantwoordelijken over te dragen.
            </li>
            <li>
              Recht van intrekking van de toestemming:<br>
              Voor zover de verwerking gebaseerd is op jouw voorafgaande toestemming, beschik je
              over het recht om die toestemming in te trekken.
            </li>
            <li>
              Uitoefening van je rechten:<br>
              Je kunt jouw rechten uitoefenen door ons daartoe te contacteren, hetzij per e-mail
              naar info@vendingmapp.com of per post naar De Maerteleire Invest &ndash; Kwaadstraat
              19 - B-9810 Nazareth, mits toevoeging van een kopie van jouw identiteitskaart.
            </li>
            <li>
              Recht om klacht in te dienen:<br>
              Je beschikt over het recht om een klacht in te dienen bij de Belgische
              Privacycommissie: Commissie voor de Bescherming van de Persoonlijke Levenssfeer. Meer
              info: http://ec.europa.eu/odr/
            </li>
          </ul>
          <h3>Wettelijke bepalingen</h3>
          <p>
            In zeldzame gevallen kan het voorkomen dat De Maerteleire Invest jouw persoonsgegevens
            ingevolge een gerechtelijk bevel of om te voldoen aan andere dwingende wet- of
            regelgeving moet onthullen. De Maerteleire Invest zal in redelijkheid pogen je van
            tevoren hierover te informeren, tenzij &eacute;&eacute;n en ander aan wettelijke
            beperkingen onderhevig is.
          </p>
          <h3>Veiligheid</h3>
          <p>
            Wij hebben veiligheidsmaatregelen ontwikkeld die aangepast zijn op technisch en
            organisatorisch vlak, om de vernietiging, het verlies, de vervalsing, de wijziging, de
            niet-toegestane toegang of de kennisgeving per vergissing aan derden van
            persoonsgegevens verzameld te vermijden alsook elke andere niet-toegestane verwerking
            van deze gegevens. In geen geval kan De Maerteleire Invest aansprakelijk worden geacht
            voor enige directe of indirecte schade die voortvloeit uit een foutief of onrechtmatig
            gebruik door een derde van de persoonsgegevens. Je dient te allen tijde de
            veiligheidsvoorschriften na te leven, onder andere door elke niet toegestane toegang tot
            jouw login en code te voorkomen. Je bent dus als enige verantwoordelijk voor het gebruik
            dat wordt gemaakt vanaf de website van jouw computer, IP-adres en van jouw
            identificatiegegevens, alsook voor de vertrouwelijkheid ervan.
          </p>
          <h3>Toegang door derden</h3>
          <p>
            Teneinde jouw persoonsgegevens te kunnen verwerken, verlenen wij toegang tot jouw
            persoonsgegevens aan onze werknemers en leveranciers. Wij garanderen een gelijkaardig
            niveau van bescherming door contractuele verplichtingen tegenstelbaar te maken aan deze
            werknemers en aangestelden, die gelijkaardig zijn aan deze privacy policy.
          </p>
          <h3>Aangifte Privacy Commissie</h3>
          <p>
            Voor deze verwerking van persoonsgegevens werd een aangifte gedaan bij de Commissie voor
            de Bescherming van de Persoonlijke Levenssfeer.
          </p>
          <h3>Links</h3>
          <p>
            Deze website kan hyperlinks naar websites of naar webpagina's van derden en andere
            partijen bevatten, of daar op een andere manier naar verwijzen. De Maerteleire Invest
            heeft geen zeggenschap over de inhoud of over andere kenmerken van deze websites of
            webpagina's en is in geen geval aansprakelijk voor de inhoud of kenmerken ervan. Het
            plaatsen van links door De Maerteleire Invest houdt op geen enkele wijze een impliciete
            goedkeuring in van de inhoud van deze websites of webpagina's.
          </p>
          <p>
            We besteden de grootst mogelijke aandacht en zorg aan de aanwezigheid van de links naar
            andere websites en derde partijen en aan de behoorlijke werking ervan. We kunnen echter
            niet garanderen dat deze links altijd werken. In geval van normaal aanvaardbare
            verstoring van de werking van deze links doen we al het mogelijke om dit binnen een zo
            kort mogelijke termijn te verhelpen. Dit kan in geen geval aanleiding geven tot het
            verschuldigd zijn van enige schadevergoeding of sanctie.
          </p>
          <h3>Aanmaken account - Paswoorden</h3>
          <p>
            Om gebruik te kunnen maken van bepaalde onderdelen van de website, waaronder, maar niet
            beperkt tot, het account, premium automaten, ..., dient de gebruiker zich te registreren
            door het aanmaken van een account. Het aanmeldingsformulier dient volledig en
            waarheidsgetrouw ingevuld te worden.
          </p>
          <p>
            Het is hierbij niet toegestaan gebruik te maken van andermans identiteit, van proxy's,
            pseudoniemen en tijdelijke e-mailadressen.
          </p>
          <p>
            De Maerteleire Invest behoudt zich het recht voor om uw aanmelding al dan niet te
            aanvaarden. Uw aanmelding kan onder meer, maar niet uitsluitend, om volgende redenen
            geweigerd worden:
          </p>
          <ul>
            <li>
              Je verstrekt niet de gevraagde gegevens voor identificatie of verstrekt onvolledige of
              onjuiste gegevens;
            </li>
            <li>
              Je tracht jezelf meerdere malen aan te melden door gebruik te maken van verschillende
              namen;
            </li>
            <li>Je maakt gebruik van andermans identiteit;</li>
            <li>
              Je komt &eacute;&eacute;n of meerdere in deze algemene voorwaarden gestelde
              verplichtingen niet na of liet dit in het verleden na;
            </li>
            <li>
              De Maerteleire Invest kan redelijkerwijze aannemen dat je een dergelijke verplichting
              niet zal nakomen;
            </li>
            <li>
              Elke andere reden op grond waarvan De Maerteleire Invest redelijkerwijze kan oordelen
              dat jouw aanmelding niet aanvaard kan worden.
            </li>
          </ul>
          <p>
            Na het aanmaken van jouw account ontvang je een paswoord. Dit paswoord heb je nodig om
            toegang te krijgen tot bepaalde delen van de site. De betreding van deze delen zonder
            paswoord is strikt verboden. Indien je dergelijk paswoord hebt ontvangen of ingesteld,
            dient dit paswoord vertrouwelijk en geheim te worden gehouden. Je dient De Maerteleire
            Invest onmiddellijk in te lichten indien &eacute;&eacute;n van jouw paswoorden ter
            kennis komt van anderen dan jezelf, of indien je dergelijk paswoord hebt ontvangen van
            een partij verschillend van De Maerteleire Invest. Elk gebruik dat wordt gemaakt van
            onze diensten na geldige identificatie met jouw paswoord zal worden geacht door jou te
            zijn verricht. Indien De Maerteleire Invest reden heeft om te geloven dat de veiligheid
            van de site in gevaar is, kan De Maerteleire Invest jouw paswoord wijzigen (onverminderd
            al haar overige rechten en verhalen).
          </p>
          <h3>Opladen foto's, teksten, ...</h3>
          <p>
            Als gebruiker ben je verantwoordelijk voor de juistheid van de teksten en afbeeldingen
            welke je opgeeft of aanvaardt, evenals de titels, de logo's, de keuzes van illustraties
            en rubriek, de opgave van de identiteit van de fotograaf (indien toepasselijk) enz...
            Latere wijzigingen gebeuren onder jouw verantwoordelijkheid. Je verbindt je ertoe De
            Maerteleire Invest zonder voorbehoud te vrijwaren van elke eis die door derden zou
            worden ingesteld met betrekking tot zijn publicatie(s) via De Maerteleire Invest, daarin
            begrepen elke eis inzake beweerde intellectuele eigendomsrechten en/of domeinnamen.
          </p>
          <p>
            Enkel de gebruiker is aansprakelijk voor de wettelijkheid, de geoorloofdheid en de
            inhoud van de opgeladen informatie, evenals van webpagina's waarnaar verwezen wordt en
            alle mogelijke veruitwendigingen op alle gebied (niet limitatief veiligheid,
            auteursrecht, ...) evenals voor naleving van alle regelgeving in geval van wedstrijden,
            verkoop op afstand, kortingen, uitverkopen, vergelijkende reclame, postorderwetgeving,
            eender welke juridische gevolgen aan de gepubliceerde content.
          </p>
          <p>
            De gebruiker garandeert dat de materialen die hij aan De Maerteleire Invest bezorgt vrij
            zijn van defecten of virussen en is aansprakelijk voor om het even welke rechtstreekse
            of onrechtstreekse schade die door zulke defecten of virussen aan De Maerteleire Invest
            of derden zou veroorzaakt worden.
          </p>
          <p>
            Door materiaal op te laden via de website verleent de gebruiker automatisch het recht
            aan De Maerteleire Invest om dit materiaal te gebruiken voor eigen doeleinden, om het te
            reproduceren op alle dragers, te bewerken en te hergebruiken en mee te delen aan het
            publiek en dit voor de ganse duur van de auteursrechtelijke bescherming, op alle gekende
            dragers en wereldwijd. Door een overeenkomst (dit is ook van toepassing voor gratis
            diensten zoals een bedrijfspagina) aan te gaan met De Maerteleire Invest verleent de
            gebruiker bovendien automatisch het recht aan De Maerteleire Invest - voor zover nodig -
            om de handels- en merknamen van de gebruiker te gebruiken als referentie, in haar eigen
            communicatie of als zoekwoord in online advertentiediensten. De gebruiker garandeert dat
            hij zelf over alle nodige rechten beschikt, en met name over de auteursrechten en
            portretrechten, om dergelijk gebruiksrecht aan De Maerteleire Invest te verlenen en zal
            De Maerteleire Invest integraal vrijwaren voor elke aanspraak door derden in het kader
            van het gebruik door De Maerteleire Invest van de materialen.
          </p>
          <p>
            De gebruiker staat zelf in voor het bekomen van afdoende licenties op intellectuele
            rechten, persoonlijkheidsrechten en andere aanspraken van derden met betrekking tot
            creaties, ontwikkelingen en andere elementen die worden gebruikt.
          </p>
          <h3>Jouw advertentie, reacties, reviews, posts, foto&rsquo;s, ... op onze website</h3>
          <ul>
            <li>
              Je bent en blijft altijd zelf verantwoordelijk voor de berichten die je op onze
              website plaatst of hebt geplaatst of voor de informatie die je via de invulformulieren
              met onze partners deelt.
            </li>
            <li>
              Agressieve, onwettelijke, bedreigende, beledigende, lasterlijke, vulgaire, obscene,
              racistische, misleidende, onbeschaafde of anderszins ongepaste of irrelevante inhoud
              is niet toegestaan.
            </li>
            <li>
              Het is jou niet toegestaan om persoonlijke informatie (zoals o.m. telefoonnummers,
              e-mailadressen en priv&eacute;adressen) van jezelf of anderen via onze website publiek
              te maken. Indien je deze informatie toch zou posten, kunnen wij in geen enkel geval
              aansprakelijk worden gesteld voor de spam die men zou kunnen ontvangen.
            </li>
            <li>
              Het is niet toegestaan om een bericht te plaatsen dat verwijst of linkt naar websites,
              die de wettelijke grenzen en/of de grenzen van fatsoen overschrijden.
            </li>
            <li>
              Je zal de andere gebruikers respecteren door geen scheld- of vloekwoorden en nodeloos
              kwetsende tekst(en) te gebruiken en door in geen enkel geval onjuiste, onvolledige of
              misleidende informatie te posten over bepaalde gebruikers of derden. Met andere
              woorden je zal andere gebruikers of derden niet bewust of opzettelijk in een slecht
              daglicht plaatsen.
            </li>
            <li>
              Je verklaart dat reviews die je plaatst, gebaseerd zijn op je eigen ervaring als klant
              van het betreffende bedrijf, dat dit jouw oprechte mening is, dat je geen persoonlijke
              link hebt met het bedrijf en dat je de review vrijwillig schrijft en hiervoor geen
              enkele vergoeding ontvang.
            </li>
            <li>
              Het is niet toegestaan om foto's (of andere beeltenissen) van anderen en/of bedrijven
              op onze website te plaatsen zonder hun voorafgaande toestemming.
            </li>
            <li>
              Je mag alleen tekst of beelden plaatsen waarvan je de auteursrechten hebt of voor
              welke je de toestemming hebt om deze bijdragen elektronisch openbaar te maken en te
              verveelvoudigen. Dit geldt ook voor voor foto's die bijvoorbeeld getoond worden bij
              het delen van een link in een post.
            </li>
            <li>
              Algemener: geen inhoud die onrechtmatig, onwettelijk of op enige wijze direct of
              indirect schade kan toebrengen aan De Maerteleire Invest, aan de met De Maerteleire
              Invest verbonden ondernemingen, aan overige service providers, distributeurs, klanten
              van De Maerteleire Invest of eender welke derde of bezoeker van de website.
            </li>
            <li>
              Jouw berichten kunnen door ons worden gemodereerd na hun publicatie op onze website.
              Indien deze berichten ongewenst, ongepast, ongeoorloofd worden geacht of op enige
              wijze deze gebruiksvoorwaarden of een geldende wettelijke bepaling niet naleven, of
              voor een andere reden naar eigen interpretatie en goeddunken, kan de moderator
              beslissen dat jouw bericht volledig of gedeeltelijk niet gepost zal worden.
            </li>
          </ul>
          <h3>Aansprakelijkheidsbeperking</h3>
          <p>
            De website en hierop geleverde diensten van De Maerteleire Invest worden verleend op een
            'as is' en 'zoals beschikbaar' basis. We besteden de grootst mogelijke aandacht en zorg
            aan de inhoud van onze website. Deze inhoud is echter onderhevig aan wijzigingen, kan te
            allen tijde verwijderd worden en wordt je aangeboden zonder expliciete of impliciete
            garanties omtrent de correctheid ervan. We zijn niet aansprakelijk voor schade ingevolge
            het gebruik van de inhoud op onze website, ongeacht of deze correct of incorrect bleek,
            noch voor schade die wordt veroorzaakt door het gebruiken of verspreiden van deze
            inhoud. De Maerteleire Invest ontkent o.a. elke aansprakelijkheid voor inhoud, software,
            links of applicaties die door gebruikers op de website worden geplaatst of doorgegeven.
            De Maerteleire Invest is verder evenmin aansprakelijk voor enige directe of indirecte,
            materi&euml;le of immateri&euml;le schade als gevolg van het verwijderen, of niet kunnen
            opslaan van inhoud die door gebruikers - waaronder jijzelf - wordt geplaatst of
            doorgegeven.
          </p>
          <p>
            De Maerteleire Invest doet haar uiterste best om haar websites en tools 24 uur per dag,
            7 dagen per week beschikbaar te stellen aan haar bezoekers en Klanten. Gezien de
            technische kenmerken van smartphones, computers, websites en het internet en de noodzaak
            tot periodiek onderhoud, updates en upgrades, kan De Maerteleire Invest geen garantie
            bieden voor een ononderbroken toegang tot de website. De Maerteleire Invest zal zich
            inspannen om de redelijke gevallen van opheffing of schorsing van de toegang zo snel
            mogelijk te verhelpen. Onderbrekingen of verstoringen zijn eigen aan de dienstverlening
            via internet en kunnen dus niet beschouwd worden als tekortkomingen door De Maerteleire
            Invest en zullen geen aanleiding geven tot schadevergoedingen in name van De Maerteleire
            Invest.
          </p>
          <p>
            Een opheffing en/of schorsing, of het verloren gaan van data, kan worden veroorzaakt
            door technische problemen, zoals, maar niet beperkt tot, een server crash of een fout,
            bug, virus of hacking, welke problemen kan veroorzaken op de website. Dergelijke
            problemen zijn specifiek aan het leveren van dit soort diensten en kunnen op geen enkele
            wijze worden gelijkgesteld met het falen in hoofde van De Maerteleire Invest. Zij zullen
            in geen enkele mogelijkheid kunnen leiden tot een boete of schadevergoeding in hoofde
            van De Maerteleire Invest. Evenmin zal De Maerteleire Invest hiervoor aansprakelijk
            kunnen gehouden worden voor enig directe of indirecte schade aan derden, ongeacht de
            reden.
          </p>
          <p>
            Wij modereren, via de tussenkomst van moderators, geposte advertenties, berichten en
            artikels op een best effort manier. Wij hebben ook een hele reeks sancties ter
            beschikking in geval wij een inbreuk vaststellen op onze gebruiksvoorwaarden. Wij
            benadrukken echter dat wij niet aansprakelijk zijn voor de inhoud van gepubliceerde
            berichten, vragen en artikels. De inhoud van gepubliceerde berichten, vragen en artikels
            wordt dus jou ter beschikking gesteld zonder expliciete of impliciete garanties omtrent
            de correctheid ervan. Wij zijn niet aansprakelijk voor schade ingevolge het gebruik van
            deze inhoud, ongeacht of deze correct of incorrect bleek, noch voor schade die wordt
            veroorzaakt door het gebruiken of verspreiden van deze inhoud.
          </p>
          <p>
            Wij garanderen niet dat gepubliceerde berichten, vragen en artikels geen inbreuk plegen
            op de rechten van derden, noch dat de berichten en antwoorden correct, volledig en
            up-to-date zijn. Wij wijzen alle aansprakelijkheid af voor de inhoud van gepubliceerde
            berichten, vragen en artikels die afkomstig zijn van onze partners, en die niet correct
            zouden blijken te zijn of die in strijd zouden blijken te zijn met de rechten van
            derden, met geldende strafwetten of met de openbare orde en de goede zeden. Indien je de
            aanwezigheid van zulke inhoud vaststelt, dien je ons hiervan onverwijld op de hoogte te
            stellen, zodat wij de gepaste maatregelen kunnen nemen.
          </p>
          <h3>Weigering</h3>
          <p>
            De Maerteleire Invest behoudt zich het recht voor om, naar eigen inschatting, Bezoekers
            de toegang tot, of het gebruik van, haar Website en/of diensten te weigeren.
          </p>
          <p>De weigering kan, onder andere maar niet uitsluitend, de volgende redenen hebben:</p>
          <ul>
            <li>
              De Bezoeker voldoet niet aan een of meer van de verplichtingen in deze Voorwaarden,
              heeft deze niet eerder nageleefd of De Maerteleire Invest mag er redelijkerwijs van
              uitgaan dat de Bezoeker in de toekomst niet aan deze verplichtingen zal voldoen;
            </li>
            <li>
              Een andere reden op basis waarvan De Maerteleire Invest redelijkerwijs van mening kan
              zijn dat toegang moet worden geweigerd.
            </li>
          </ul>
          <h3>Overmacht</h3>
          <p>
            In geval van overmacht is De Maerteleire Invest niet gehouden haar verplichtingen na te
            leven. In dat geval kan De Maerteleire Invest haar verplichtingen opschorten voor de
            duur van de overmacht, ofwel de overeenkomst definitief ontbinden. Overmacht is elke
            omstandigheid buiten de wil en controle van De Maerteleire Invest, die de nakoming van
            haar verplichtingen geheel of gedeeltelijk verhindert. Hieronder wordt onder meer
            begrepen: stakingen, brand, bedrijfsstoringen, machinedefecten, informaticadefecten,
            systeemfouten, energiestoringen, storingen in een (telecommunicatie-)netwerk of
            verbinding of gebruikte communicatiesystemen en/of het op enig moment niet beschikbaar
            zijn van de website, ...
          </p>
          <h3>Algemene verkoopsvoorwaarden</h3>
          <p>
            De&nbsp;Algemene verkoopsvoorwaarden&nbsp;van De Maerteleire Invest zijn steeds van
            toepassing (ook voor gratis producten zoals de gratis bedrijfspagina's, e-books,
            downloads, ...).
          </p>
          <p>
            Wij waarderen het vertrouwen dat u in De Maerteleire Invest stelt en nemen jouw rechten
            ernstig. Deze verkoopsvoorwaarden zijn van toepassing op onze website. Door gebruik te
            maken van deze website verbind je jezelf ertoe deze verkoopsvoorwaarden na te leven en
            te aanvaarden. Om een bestelling te kunnen plaatsen, moet je minstens 18 jaar oud zijn.
            Als je geen 18 bent, vragen we je om de bestelling door je ouders of wettelijke voogd te
            laten plaatsen. Naast deze verkoopsvoorwaarden zijn ook de andere gebruiksvoorwaarden op
            deze pagina van toepassing.
          </p>
          <h3>Aangeboden producten</h3>
          <p>
            Alle producten die via onze webshop aangeboden worden, zijn zo volledig mogelijk en te
            goeder trouw beschreven. Als we gebruik maken van afbeeldingen, zijn deze een
            waarheidsgetrouwe weergave van de aangeboden goederen en/of diensten. Vergissen is
            echter menselijk en als we ons overduidelijk vergist hebben, zijn we niet verplicht om
            je alsnog te leveren. De aanbiedingen en de prijzen zijn geldig op de dag wanneer ze op
            onze website aangeboden worden en voor de periode vermeld in onze flyers, folders, ....
            De tarieven voor adverteerders steeds exclusief BTW tenzij anders vermeld. De
            Maerteleire Invest kan niet aansprakelijk gesteld worden voor (druk)fouten die in zijn
            aanbiedingen voorkomen.
          </p>
          <h3>Overeenkomst</h3>
          <p>
            Er is sprake van een geldige overeenkomst tussen jou (klant) en De Maerteleire Invest
            (verkoper) wanneer De Maerteleire Invest de bevestiging ontvangt van jouw bestelling op
            zijn website. De Maerteleire Invest kan echter beslissen de geldigheid van de
            overeenkomst te onderwerpen aan andere voorwaarden, in het geval bijvoorbeeld van
            belangrijke bestellingen of voorafbetalingen, bestellingen gedaan door minderjarigen of
            wanneer vorige bestellingen niet betaald werden.
          </p>
          <h3>Levering</h3>
          <p>
            De fysieke goederen worden verzonden op kosten van de klant naar het door de klant
            aangeduide adres bij bestelling. Voor digitale bestanden zoals eBooks, ... ontvang je
            een download link per e-mail en volgt geen fysieke levering. De Maerteleire Invest doet
            zijn uiterste best om ervoor te zorgen dat je jouw product zo spoedig mogelijk ontvangt.
            Aangegeven levertijden zijn louter indicatief en De Maerteleire Invest kan niet
            aansprakelijk worden gesteld voor eventuele vertragingen. Indien het product onmogelijk
            geleverd kan worden, wordt de overeenkomst van rechtswege ontbonden. Bij gedeeltelijke
            levering zal elke levering als afzonderlijke verkoop worden aanzien.
          </p>
          <h3>eBooks</h3>
          <p>
            Digitale bestanden zoals eBooks (.pdf, .ePub, ...), ... worden beschermd door het
            auteursrecht. Het is bijgevolg niet toegestaan bestanden volledig of gedeeltelijk te
            kopi&euml;ren, te wijzigen of fragmenten ervan in een ander werk te verwerken. Het
            verspreiden van deze bestanden, of aan het publiek mee te delen, is uitdrukkelijk
            verboden. Zo is het onder meer niet toegestaan kopie&euml;n te verspreiden (zelfs
            wanneer het om een gratis download gaat), de bestanden op het internet te plaatsen of de
            werken te verhuren. Alle digitale bestanden die via onze website gedownload worden,
            bevatten zowel zichtbare watermerken als onzichtbare beveiligingen waarmee de
            verspreiding van deze bestanden getraceerd kan worden. Elke inbreuk zal onmiddellijk
            worden vervolgd.
          </p>
          <h3>Recht van afstand</h3>
          <p>
            De Belgische wet op de handelspraktijken en de informatie en de bescherming van de
            consument, laat de klant toe binnen de 14 werkdagen na ontvangst van het product de
            verkoper te informeren dat hij/zij van de verkoop afziet (info@vendingmapp.com). De
            goederen dienen in ongeopende verpakking te volgen binnen een aanvaardbare termijn na
            deze kennisgeving (3 werkdagen). Indien je een product wenst terug te sturen omdat je
            van mening veranderde, kan je dit doen binnen de 14 dagen die volgen op de ontvangst.
            Wij zullen jouw bestelling terugbetalen zodra wij het geretourneerde artikel ontvangen.
            Je zal zelf de verzendkosten betalen om het product terug te sturen en de retourzending
            gebeurt op jouw risico. De Maerteleire Invest kan geretourneerde artikelen weigeren of
            niet terugbetalen wanneer de producten door de klant beschadigd werden. Bij
            gepersonaliseerde digitale bestanden zoals onder meer e-books is aanspraak op het
            retourrecht uitgesloten.
          </p>
          <h3>Betaling</h3>
          <p>
            Tenzij we anders met u overeengekomen zijn, aanvaarden we via onze webshop alleen
            domicili&euml;ring via onze website. Indien in uitzonderlijk geval een betaling op
            factuur wordt toegestaan gelden volgende voorwaarden. Facturen dienen contant betaald te
            worden binnen 14 kalenderdagen op de maatschappelijke zetel van De Maerteleire Invest.
            Op de betalingen na de gestelde vervaldag gedaan, wordt van rechtswege en zonder enige
            ingebrekestelling, een verwijlintrest geheven van 1% per maand, waarbij het begin van
            een maand geldt als een volledige maand. Op het bedrag van de op de vervaldag onbetaald
            gebleven facturen zal een vergoeding van 15% op het factuurbedrag, met een minimum van
            &euro; 125 geheven worden als tussenkomst in de door de wanbetaling veroorzaakte
            administratie- en andere kosten. Alle gerechtelijke en buitengerechtelijke
            invorderingskosten van facturen zijn voor rekening van de klant. Indien 1 factuur
            laattijdig wordt betaald, worden alle facturen in hoofde van deze klant onmiddellijk
            opeisbaar.
          </p>
          <h3>Facturen</h3>
          <p>
            Indien je daarvoor opteert bij bestelling, ontvang je van De Maerteleire Invest een
            factuur bij je betaalbevestiging. Facturen kunnen enkel verstrekt worden wanneer we in
            het bezit zijn van correcte facturatiegegevens. Het is niet mogelijk achteraf een
            factuur te maken voor bestellingen waarvoor geen factuur werd aangevraagd.
          </p>
          <p>
            Betwistingen van facturen dienen te gebeuren binnen 5 werkdagen na ontvangst van de
            factuur. Gebrek aan reactie binnen vijf werkdagen houdt de definitieve aanvaarding van
            de betreffende factuur in. Behoudens andersluidend beding, zijn alle facturen betaalbaar
            uiterlijk 14 dagen na de factuurdatum op de maatschappelijke zetel van De Maerteleire
            Invest, ongeacht eventueel hangende klachten of de manifestatie daarvan. Bij
            niet-betaling van een factuur op de vervaldag, is De Maerteleire Invest bovendien van
            rechtswege en zonder voorafgaande ingebrekestelling gerechtigd, naar haar eigen keuze,
            de levering van de dienst op te schorten tot wanneer alle uitstaande rekeningen -
            inclusief de intresten en de forfaitaire schadevergoeding - werden aangezuiverd of om de
            overeenkomst met jou van rechtswege, zonder voorafgaande ingebrekestelling en zonder
            verdere verwittiging te ontbinden.
          </p>
          <h3>Overmacht</h3>
          <p>
            In geval van overmacht zijn wij niet gehouden onze verplichtingen na te komen. In dat
            geval kunnen wij ofwel onze verplichtingen opschorten voor de duur van de overmacht,
            ofwel de overeenkomst definitief ontbinden.
          </p>
          <h3>Toepasselijke wetgeving</h3>
          <p>Zie onderaan deze pagina.</p>
          <p>
            Bij buitengerechtelijke regeling van het geschil is de Consumentenombudsdienst van de
            FOD Economie bevoegd om elke aanvraag tot buitengerechtelijke regeling van
            consumentengeschillen te ontvangen. Deze zal op haar beurt de aanvraag ofwel zelf
            behandelen ofwel doorsturen naar een gekwalificeerde entiteit. Je kan de
            Consumentenombudsdienst bereiken via deze link: www.consumentenombudsdienst.be
          </p>
          <p>
            Bij geschillen met een grensoverschrijdend karakter kan je bovendien beroep doen op het
            Online Dispute Resolution platform van de Europese Unie via deze link: ec.europa.eu/odr
          </p>
          <h3>Algemene bepalingen</h3>
          <p>Laatste update van deze voorwaarden: 27/08/2021</p>
          <p>
            Aanpassingen - Als we deze gebruiksvoorwaarden wijzigen, is de nieuwe versie beschikbaar
            op onze Website en is deze nieuwe versie van toepassing vanaf je eerstvolgende bezoek
            aan onze website. Vanaf dat ogenblik word je geacht hiervan kennis te hebben genomen en
            hiermee akkoord te gaan. Het is dus van belang bij elk bezoek aan de Website de datum
            van aanpassing hierboven vermeld, na te gaan.
          </p>
          <p>
            Aanvaarding - Door onze website te gebruiken, registratie, ... aanvaard je alle
            bepalingen van deze voorwaarden.
          </p>
          <p>
            Contact - Voor alle bijkomende inlichtingen of opmerkingen met betrekking tot deze
            voorwaarden, kan je contact opnemen met ons op het volgend adres: De Maerteleire Invest,
            kwaadstraat 19, B-9810 Nazareth of via info@vendingmapp.com Elke feedback met betrekking
            tot de website of betwisting dient aangetekend aan het hierboven vermelde adres te
            worden verzonden. Elke mededeling van ons aan jou gebeurt via de website of per e-mail,
            naar onze keuze en mogelijkheid.
          </p>
          <h3>Toepasselijke wetgeving</h3>
          <p>
            Deze voorwaarden worden beheerst, ge&iuml;nterpreteerd en uitgevoerd in overeenstemming
            met het Belgische recht, dat exclusief van toepassing is bij elk eventueel geschil.
          </p>
          <p>
            De rechtbanken van het rechtsgebied gent zijn exclusief bevoegd om te oordelen over elk
            geschil dat zou kunnen voortvloeien uit de interpretatie of de uitvoering van deze
            voorwaarden. Indien het een zaak betreft die behoort tot de bevoegdheid van het
            Vredegerecht komen partijen uitdrukkelijk overeen dat alsdan het Kanton Deinze bevoegd
            is.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./TermsAndConditions.controller.js"></script>
<style lang="scss" src="./TermsAndConditions.styles.scss"></style>
